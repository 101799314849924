import { Component, OnInit } from '@angular/core';
import { ApplicationApi } from '../../../@core/backend/common/api/application.api';
import { NbAuthJWTToken,NbAuthService } from '@nebular/auth';
import { Router } from '@angular/router';
// import { CountriesService } from '../../@core/data/countries.service';
// import { CountriesService } from '../../@core/data/countries.service';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { Data } from '../../../../assets/data/data';
import { saveAs } from 'file-saver';
import { environment } from '../../../../environments/environment.prod';

// import { Data } from '../../shared/data';
@Component({
  selector: 'ngx-admin-total',
  templateUrl: './admin-total.component.html',
  styleUrls: ['./admin-total.component.scss']
})
export class AdminTotalComponent implements OnInit {
  Countries: any [];
  p: number = 1;
  public filterText: string;
  public filterPlaceholder: string;
  public filterInput = new FormControl();
  selectedYear ='2019'
  studentdata: any;
  active: any;
  searchForm:FormGroup;
  name : string;
  email : string;
  isLoadingResults: boolean = false;
  studentLength : any;
  dbConfig= environment.dbConfig

  totalLength: any;
  constructor(  private api: ApplicationApi, 
    private authService : NbAuthService,
    private router : Router,
    // protected countries :CountriesService,
    public toasterService: NbToastrService,
    protected globalVar:Data,
    private formBuilder:FormBuilder,) {
    
   }

  ngOnInit() {
    this.filterText = "";
    this.filterPlaceholder = "Local Search";
    this.refresh(null,null,null,null,null);
    this.searchForm = this.formBuilder.group({
      nameCtrl:[''],
      emailCtrl:[''],
      idCtrl : [''],
      orderCtrl:[''],
      EnrollnoCtrl:['']
    })
  }

  handleClick(user_id,app_id) {
    
    
    
    this.router.navigate(['pages/adminView'],{queryParams:{userId : user_id, app_id : app_id, viewFrom : 'total'}});
  }
  pageChanged(p){
    this.p = p;
    this.globalVar.ViewpageValue=p;
     this.refresh(this.searchForm.controls.idCtrl.value,this.searchForm.controls.nameCtrl.value,this.searchForm.controls.emailCtrl.value,this.searchForm.controls.orderCtrl.value,this.searchForm.controls.EnrollnoCtrl.value);
  
  }

  search(){
    if(this.searchForm.controls.idCtrl.value || this.searchForm.controls.nameCtrl.value || this.searchForm.controls.emailCtrl.value || this.searchForm.controls.orderCtrl.value || this.searchForm.controls.EnrollnoCtrl.value ){
      this.refresh(this.searchForm.controls.idCtrl.value,this.searchForm.controls.nameCtrl.value,this.searchForm.controls.emailCtrl.value,this.searchForm.controls.orderCtrl.value,this.searchForm.controls.EnrollnoCtrl.value);
    }else{
      alert("Please specify any of the search criteria")
    }
  }
  refresh(application_id,student_name, email,Order,enrollNo){
    this.isLoadingResults = true;
    // 
    this.api.getAll_PaidApplications(this.p,application_id,student_name,email,Order,enrollNo).subscribe(data =>{
      this.isLoadingResults = false;
      this.studentdata = data['items'];
      
      
      this.studentLength = data['total_count'];
      this.totalLength = this.studentLength.totalLength;
      // this.active=data['counts'];
    })
    this.filterInput
      .valueChanges
      // .debounceTime(200)
      .subscribe(term => {
       this.filterText = term;
    });
  }
  //downloadExcel sheet 
  downloadExcel(tabtype){
    
     
         this.api.downloadExcel(tabtype).subscribe((response)=>{
          if(response['status']==200){
            // const dbConfig ='D:/pdc2/GUPDCServer'

    var pdf = this.dbConfig+"/public/upload/Excel/"+tabtype+'.xlsx'
    this.api.downloadFiles(pdf)
    .subscribe(data => {
      saveAs(data, tabtype+'.xlsx');
    });
          }
            
             })
    
      };


}
