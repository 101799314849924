import { Component, OnInit } from '@angular/core';
import { ApplicationApi } from '../../../@core/backend/common/api/application.api';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { Router } from '@angular/router';
// import { CountriesService } from '../../@core/data/countries.service';
// import { CountriesService } from '../../@core/data/countries.service';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { Data } from '../../../../assets/data/data';
// import { Data } from '../../shared/data';
@Component({
  selector: 'ngx-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {
  Countries: any[];
  p: number = 1;
  public filterText: string;
  public filterPlaceholder: string;
  public filterInput = new FormControl();
  selectedYear = '2019'
  studentdata: any;
  active: any;
  searchForm: FormGroup;
  name: string;
  email: string;
  isLoadingResults: boolean = false;
  studentLength: any;
  totalLength: any;
  constructor(private api: ApplicationApi,
    protected globalVar: Data,

    private authService: NbAuthService,
    private router: Router,
    // protected countries :CountriesService,
    public toasterService: NbToastrService,
    // protected globalVar:Data,
    private formBuilder: FormBuilder,) {

  }

  ngOnInit() {
    this.filterText = "";
    this.filterPlaceholder = "Local Search";
    this.refresh(null, null);
    this.searchForm = this.formBuilder.group({
      nameCtrl: [''],
      emailCtrl: ['']
      // idCtrl : [''],
    })
  }

  viewMore(category, userId, user_type, course_id) {


    this.router.navigate(['pages/adminView'], { queryParams: { userId: userId, viewFrom: "studentManagement" } });
  }

  handleClick(user_id, type) {


    this.router.navigate(['pages/adminView'], { queryParams: { userId: user_id, viewFrom: type } });
  }


  pageChanged(p) {
    this.p = p;
    this.globalVar.ViewpageValue = p;
    this.refresh(this.searchForm.controls.nameCtrl.value, this.searchForm.controls.emailCtrl.value);
  }



  // create(){
  //   this.api.createapplication('').subscribe(data =>{

  //   })
  // }
  refresh(student_name, email) {
    this.isLoadingResults = true;
    // 
    this.api.getallstudents(this.p, student_name, email).subscribe(data => {
      this.isLoadingResults = false;
      this.studentdata = data['items'];

      this.studentLength = data['total_count'];
      this.totalLength = this.studentLength.totalLength;

      // this.active=data['counts'];
    })
    this.filterInput
      .valueChanges
      // .debounceTime(200)
      .subscribe(term => {
        this.filterText = term;
      });
  }

  search() {
    if (this.searchForm.controls.nameCtrl.value || this.searchForm.controls.emailCtrl.value) {
      this.refresh(this.searchForm.controls.nameCtrl.value, this.searchForm.controls.emailCtrl.value);
    } else {
      alert("Please specify any of the search criteria")
    }
  }

}
