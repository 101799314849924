import { Component, OnInit } from '@angular/core';
import { ApplicationApi } from '../../../@core/backend/common/api/application.api';
import { NbAuthJWTToken,NbAuthService } from '@nebular/auth';
import { Router,ActivatedRoute } from '@angular/router';
// import { CountriesService } from '../../@core/data/countries.service';
// import { CountriesService } from '../../@core/data/countries.service';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import {NbDialogService } from '@nebular/theme';
import { NotesComponent } from '../../user/notes.component';
import { Data } from '../../../../assets/data/data';
import { Subject } from 'rxjs';
import { UserStore } from '../../../@core/stores/user.store';
import { User, UserData } from '../../../@core/interfaces/common/users';
import { takeUntil } from 'rxjs/operators';
// import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'ngx-admin-verified',
  templateUrl: './admin-verified.component.html',
  styleUrls: ['./admin-verified.component.scss']
})
export class AdminVerifiedComponent implements OnInit {
  Countries: any [];
  p: number = 1;
  public filterText: string;
  public filterPlaceholder: string;
  public filterInput = new FormControl();
  selectedYear ='2019'
  studentdata: any;
  active: any;
  searchForm:FormGroup;
  printdate:FormGroup;

  name : string;
  email : string;
  isLoadingResults: boolean = false;
  studentLength : any;
  displayVideo2:boolean=false;
  userId : any;
  student_name: any;
  displayNo: boolean=false;
  applicationdata: any;
  app_id: any;
  notes: any;
  toastrService: any;
  print_date: any;
  user_id: any;
  totalLength: any;
  protected readonly unsubscribe$ = new Subject<void>();
  user: any;
  id: any;
  role: string;
  adminemail: any;
  usersData: any;
  userid: any;
  email_admin: any;
  type: any;
  message: any;
  constructor(  private api: ApplicationApi, 
    private userStore: UserStore,
    private usersService: UserData,
    private authService : NbAuthService,
    private router : Router,
    // protected countries :CountriesService,
    public toasterService: NbToastrService,
    public dialogservice: NbDialogService,
    private route: ActivatedRoute,
    private globalVar : Data,

        private formBuilder:FormBuilder,) {
    this.userId = this.route.snapshot.queryParamMap.get('userId');

   }

  ngOnInit() {
    this.filterText = "";
    this.filterPlaceholder = "Local Search";
    this.refresh(null,null,null,null);
    this.searchForm = this.formBuilder.group({
      nameCtrl:[''],
      emailCtrl:[''],
      pickdatectrl:[''],
      EnrollnoCtrl:[''],
      idCtrl : [''],
    })
     
    if(this.userStore.getUser() == undefined){      
      this.usersService.getCurrentUser().pipe(takeUntil(this.unsubscribe$))
      .subscribe((user) => {
            this.id=this.user.id;
            this.user = user;
            this.role= user.role;
            this.email = this.user.email;
            this.email_admin = user.data.email;
            
            
            
      })
    
          this.userStore.onUserStateChange()
          .pipe(
            takeUntil(this.unsubscribe$),
          )
          .subscribe((user: User) => {
            this.id=user.id;
            this.user = user;
            this.role= user.role;
            this.email = this.user.email;   
            this.email_admin = this.user.email;
 
          });
    
      }else{        
        this.user=this.userStore.getUser();
        this.id=this.user.id;
        this.role=this.user.role;
        this.email_admin = this.user.email;

    
      }
      var userId=this.route.snapshot.queryParamMap.get('userId');
      
        // this.api.getAdminSideDetails(userId).subscribe(data => {
        //   this.usersData= data['data'][0] ? data['data'][0] : null;    
        //   });
  }


  handleClick(user_id,app_id) {
    
    
    
    this.router.navigate(['pages/adminView'],{queryParams:{userId : user_id, app_id : app_id, viewFrom : 'verified'}});
  }
  pageChanged(p){
    this.p = p;
   this.globalVar.ViewpageValue=p;
    this.refresh(this.searchForm.controls.idCtrl.value,this.searchForm.controls.nameCtrl.value,this.searchForm.controls.emailCtrl.value,this.searchForm.controls.EnrollnoCtrl.value);
  }

  search(){
    if(this.searchForm.controls.idCtrl.value || this.searchForm.controls.nameCtrl.value || this.searchForm.controls.emailCtrl.value || this.searchForm.controls.EnrollnoCtrl.value){
      this.refresh(this.searchForm.controls.idCtrl.value,this.searchForm.controls.nameCtrl.value,this.searchForm.controls.emailCtrl.value,this.searchForm.controls.EnrollnoCtrl.value);
    }else{
      alert("Please specify any of the search criteria")
    }
  }
  addNotes(userid,app_id){
    
    
    this.dialogservice.open(NotesComponent, {
      context: {

           app_id: app_id,
           type:'notes',
           userid:userid,
           email_admin :this.email_admin
      },
    }).onClose.subscribe(data=>{
      this.getapplication()
    })

  }
  getapplication(){
    this.api.getapplication(this.app_id).subscribe(data => {
      if(data['status'] == 200){
        this.applicationdata=data['data']
        this.notes= this.applicationdata[0].notes ? this.applicationdata[0].notes : ''
        
       
      }else{
        this.toastrService.danger('Error Occured','Error',{preventDuplicates: true});
      }
    });
   }
  refresh(application_id,student_name, email,Enrollno){
    this.student_name = student_name;
    this.isLoadingResults = true;
    // 
    this.api.getAll_verifiedApplications(this.p,application_id,student_name,email,Enrollno).subscribe(data =>{
      this.isLoadingResults = false;
      this.studentdata = data['items'];
      
      this.studentLength = data['total_count'];
      
      this.totalLength = this.studentLength.totalLength;

      // this.active=data['counts'];
    })
    this.filterInput
      .valueChanges
      // .debounceTime(200)
      .subscribe(term => {
       this.filterText = term;
    });
  }
  // save(){
    
    
  
  //   this.print_date = this.searchForm.controls.pickdatectrl.value;
  //   
  //   
  //   
    
  //   
  
  //   // this.api.pickdate(this.user_id,this.print_date).subscribe((data: any) => {
  //   //   if(data['status'] ==  200){
  //   //     this.toasterService.success('Application ' + 'verifed successfully');
  //   //   }
  //   // })

  // }

  Verify(userId,app_id,email_admin){
if(email_admin.includes('@edulab.in')){
  this.toasterService.warning("you don't have access to verify application");
}
else{
this.api.generateCertificate(userId,app_id,'',email_admin).subscribe((data: any) => {
  if(data['status'] ==  200){
    this.toasterService.success('Application ' + app_id + 'verifed successfully');
    this.refresh(this.student_name,'', this.email,'');
  }
})}
    // this.api.generateCertificate(userId,app_id).subscribe((data: any) => {
    //   if(data['status'] ==  200){
    //     this.toasterService.success('Application ' + app_id + 'verifed successfully');
    //     this.refresh(this.student_name, this.email);
    //   }
    // })
  }

  BackToPending(id,user_id,type,email_admin){
    
        //this.display = true;
        this.email_admin=email_admin
        this.user_id=user_id;
        this.app_id=id;
        this.type= type;
    this.email_admin=email_admin
    if(email_admin.includes('@edulab.in')){
      this.toasterService.warning("you don't have access to back the application")
    }
          // 
          // 
          else{
      this.api.setVerified(this.app_id ,this.type,this.user_id,this.email_admin).subscribe((data: any) => {
        if(data['status'] ==  200 ){
          
          this.toasterService.success('Application ' + this.app_id  +  data['message']);
          //this.display =false;
          this.refresh(null,null,null,null);
        }
      })
    }
    
      }

}
