<!-- <nb-card>
    <nb-card-header>

        <div class="modal-body">
            <form novalidate="" class="ng-pristine ng-invalid ng-touched"><label class="form-label2 color-theme">Enter
                    your queryyy</label><textarea placeholder="Enter your query" formcontrolname="query" id="query"
                    class="form-control form-input ng-pristine ng-invalid ng-touched"></textarea>
            
            </form>
            <button class="btn bg-theme color-white mt-3">Submit</button>
        </div>
    </nb-card-header>
</nb-card>  -->
<!-- <nb-accordion>
    <nb-accordion-item expanded=false>
     <nb-accordion-item-header>What is the process of getting provisional certificate?</nb-accordion-item-header>
     <nb-accordion-item-body >
        <div >
            <nb-card-body>
                <p>Go on HSNCuniversity.studentscenter.in and register on Provisional Certificate
 .</p>
            </nb-card-body>
        </div>
    </nb-accordion-item-body>
    </nb-accordion-item>
  <br>
  <nb-accordion-item-header>How long is Provisional Certificate
 valid?</nb-accordion-item-header>
     <nb-accordion-item-body >
        <div >
            <nb-card-body>
                <p>around 6 months
                    Provisional Certificate
  is a temporary document provided by a university until a permanent
                    degree is issued and imparted on convocation. Generally, it continues to be a valid certificate
                    for around 6 months or until a degree is issued to the students. It has no expiry date per se.</p>
            </nb-card-body>
        </div>
    </nb-accordion-item-body> -->


<!-- <nb-accordion>
        <nb-accordion-item expanded=false>
            <nb-accordion-item-header>How long is Provisional Certificate
 valid?</nb-accordion-item-header>
            <nb-accordion-item-body >
               <div >
                   <nb-card-body>
                       <p>around 6 months
                        Provisional Certificate
  is a temporary document provided by a university until a permanent
                        degree is issued and imparted on convocation. Generally, it continues to be a valid certificate
                        for around 6 months or until a degree is issued to the students. It has no expiry date per se.</p>
                   </nb-card-body>
               </div>
            </nb-accordion-item> 
        </nb-accordion-item-body >  -->
<nb-card status="success" style="height: 90%;">
    <nb-card-header>
        <div style="text-align: center;">
            Frequently Asked Questions?
        </div>
    </nb-card-header>
    <nb-card-body>
        <div class="modal-body">
            <form novalidate="" class="ng-pristine ng-invalid ng-touched"><label class="form-label2 color-theme">You can
                    contact here to enquire</label>
                <p>9930003151 , <a href="">hsncpdc@studentscenter.in</a> </p>
                <nb-accordion type="info">
                    <nb-accordion-item expanded=false>
                        <nb-accordion-item-header>What is the process of getting provisional
                            certificate?</nb-accordion-item-header>
                        <nb-accordion-item-body>
                            <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>Go on HSNCuniversity.studentscenter.in and register on Provisional
                                            Certificate
                                            .</p>
                                    </form>
                                </nb-card-body>
                            </nb-card>
                        </nb-accordion-item-body>
                    </nb-accordion-item>
                </nb-accordion>

                <br>
                <nb-accordion type="info">
                    <nb-accordion-item expanded=false>
                        <nb-accordion-item-header>How long is Provisional Certificate
                            valid?</nb-accordion-item-header>
                        <nb-accordion-item-body>
                            <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>around 6 months
                                            Provisional Certificate
                                            is a temporary document provided by a university until a permanent
                                            degree is issued and imparted on convocation. Generally, it continues to be
                                            a valid certificate
                                            for around 6 months or until a degree is issued to the students. It has no
                                            expiry date per se.</p>
                                    </form>
                                </nb-card-body>
                            </nb-card>
                        </nb-accordion-item-body>
                    </nb-accordion-item>
                </nb-accordion>
                <br>
                <nb-accordion type="info">
                    <nb-accordion-item expanded=false>
                        <nb-accordion-item-header>Is Provisional Certificate
                            important?</nb-accordion-item-header>
                        <nb-accordion-item-body>
                            <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>The Provisional Certificate serves as documentation that the original
                                            certificate has not
                                            yet been issued. Until the final degree arrives, the certificate is
                                            essential. It can be used
                                            for future opportunities such as employment or higher education</p>
                                    </form>
                                </nb-card-body>
                            </nb-card>
                        </nb-accordion-item-body>
                    </nb-accordion-item>
                </nb-accordion>
                <br>
                <nb-accordion type="info">
                    <nb-accordion-item expanded=false>
                        <nb-accordion-item-header>Is provisional certificate and original degree
                            same?</nb-accordion-item-header>
                        <nb-accordion-item-body>
                            <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>A degree certificate is the official permanent certificate you get after
                                            completing your
                                            college degree. A provisional certificate is a temporary copy. It is useful
                                            for proving
                                            that you have completed your degree until you receive the official copy.</p>
                                    </form>
                                </nb-card-body>
                            </nb-card>
                        </nb-accordion-item-body>
                    </nb-accordion-item>
                </nb-accordion>
                <br>
                <nb-accordion type="info">
                    <nb-accordion-item expanded=false>
                        <nb-accordion-item-header>Can I get provisional certificate if I have
                            backlogs?</nb-accordion-item-header>
                        <nb-accordion-item-body>
                            <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>No. Degrees, be it Provisional or the Real one, are granted only after you
                                            successfully pass all the coursework without any backlog.</p>
                                    </form>
                                </nb-card-body>
                            </nb-card>
                        </nb-accordion-item-body>
                    </nb-accordion-item>
                </nb-accordion>
                <br>
                <nb-accordion type="info">
                    <nb-accordion-item expanded=false>
                        <nb-accordion-item-header>What is the value of provisional
                            certificate?</nb-accordion-item-header>
                        <nb-accordion-item-body>
                            <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>The Provisional Certificate
                                            is treated as original degree as long as the original degree is not
                                            issued.</p>
                                    </form>
                                </nb-card-body>
                            </nb-card>
                        </nb-accordion-item-body>
                    </nb-accordion-item>
                </nb-accordion>
                <br>
                <nb-accordion type="info">
                    <nb-accordion-item expanded=false>
                        <nb-accordion-item-header>Can we use provisional certificate instead of degree
                            certificate?</nb-accordion-item-header>
                        <nb-accordion-item-body>
                            <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>yes, you may produce your Provisional Certificate
                                            until you get the final degree
                                            certificate.</p>
                                    </form>
                                </nb-card-body>
                            </nb-card>
                        </nb-accordion-item-body>
                    </nb-accordion-item>
                </nb-accordion>
                <br>
                <nb-accordion type="info">
                    <nb-accordion-item expanded=false>
                        <nb-accordion-item-header>Is provisional certificate valid for abroad
                            studies?</nb-accordion-item-header>
                        <nb-accordion-item-body>
                            <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>As per the nature of the provisional certificate, you can use it until you
                                            get your degree
                                            from your university. It is free from any expiry date for use in your home
                                            country to
                                            pursue further education or catch job opportunities. However, you cannot use
                                            it to
                                            enrol in a foreign university</p>
                                    </form>
                                </nb-card-body>
                            </nb-card>
                        </nb-accordion-item-body>
                    </nb-accordion-item>
                </nb-accordion>
                <br>
                <nb-accordion type="info">
                    <nb-accordion-item expanded=false>
                        <nb-accordion-item-header>Is provisional certificate and consolidated Marksheet
                            same?</nb-accordion-item-header>
                        <nb-accordion-item-body>
                            <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>It is a temporary certificate until the original certificate came. By using
                                            provisional
                                            certificate then you can easily use that one to search for a job.
                                            Consolidated Marks
                                            Card (CMC) of All the Semesters looks like a marks card (colorful)
                                            containing marks of
                                            the 1st semester to 8th semester.</p>
                                    </form>
                                </nb-card-body>
                            </nb-card>
                        </nb-accordion-item-body>
                    </nb-accordion-item>
                </nb-accordion>
                <br>
                <nb-accordion type="info">
                    <nb-accordion-item expanded=false>
                        <nb-accordion-item-header>How long do colleges keep certificates?</nb-accordion-item-header>
                        <nb-accordion-item-body>
                            <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>They either keep the certificate with them or after certain years like 5
                                            years, may have it
                                            to the university. You can directly visit your college and contact your
                                            department HOD
                                            or teachers or even visit the college office and make an application for the
                                            same</p>
                                    </form>
                                </nb-card-body>
                            </nb-card>
                        </nb-accordion-item-body>
                    </nb-accordion-item>
                </nb-accordion>
                <br>
                <nb-accordion type="info">
                    <nb-accordion-item expanded=false>
                        <nb-accordion-item-header>What is difference between migration and provisional
                            certificate?</nb-accordion-item-header>
                        <nb-accordion-item-body>
                            <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>At last, we can say that a Provisional certificate is a temporary certificate
                                            which is given
                                            to the candidates to go for their higher studies or for a job while a
                                            Migration certificate is
                                            given to a candidate to continue their higher study or to change any
                                            university in
                                            between the course.</p>
                                    </form>
                                </nb-card-body>
                            </nb-card>
                        </nb-accordion-item-body>
                    </nb-accordion-item>
                </nb-accordion>
                <br>
                <nb-accordion type="info">
                    <nb-accordion-item expanded=false>
                        <nb-accordion-item-header>Is Provisional Certificate
                            enough for UK visa?</nb-accordion-item-header>
                        <nb-accordion-item-body>
                            <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>Yes, they do. If you haven&#39;t received your final degree certificate yet.
                                            Best to provide
                                            the Final degree certificate if you have it already.</p>
                                    </form>
                                </nb-card-body>
                            </nb-card>
                        </nb-accordion-item-body>
                    </nb-accordion-item>
                </nb-accordion>
                <br>
                <nb-accordion type="info">
                    <nb-accordion-item expanded=false>
                        <nb-accordion-item-header>What is the use of provisional certificate?</nb-accordion-item-header>
                        <nb-accordion-item-body>
                            <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>A provisional certificate is a type of certificate issued for a temporary
                                            period of time. If
                                            you want to apply for an admission but haven&#39;t received your original
                                            degree
                                            certificate, then you can submit your provisional certificate and secure an
                                            admission in a college.</p>
                                    </form>
                                </nb-card-body>
                            </nb-card>
                        </nb-accordion-item-body>
                    </nb-accordion-item>
                </nb-accordion>
                <br>
                <nb-accordion type="info">
                    <nb-accordion-item expanded=false>
                        <nb-accordion-item-header>Can we get a provisional certificate
                            online?</nb-accordion-item-header>
                        <nb-accordion-item-body>
                            <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>Yes you can. Register on gujuratuniversity.studentscenter.in</p>
                                    </form>
                                </nb-card-body>
                            </nb-card>
                        </nb-accordion-item-body>
                    </nb-accordion-item>
                </nb-accordion>
                <br>
                <nb-accordion type="info">
                    <nb-accordion-item expanded=false>
                        <nb-accordion-item-header>When will a provisional certificate be
                            issued?</nb-accordion-item-header>
                        <nb-accordion-item-body>
                            <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>When there is a delay in receiving the final degree certificate, your
                                            institution or university will
                                            normally issue a provisional certificate. Depending on which college you
                                            graduated from, the time it
                                            takes to receive this temporary certificate can range from 3 to 6 months.
                                        </p>
                                    </form>
                                </nb-card-body>
                            </nb-card>
                        </nb-accordion-item-body>
                    </nb-accordion-item>
                </nb-accordion>
                <br>
                <nb-accordion type="info">
                    <nb-accordion-item expanded=false>
                        <nb-accordion-item-header>What if provisional certificate is lost?</nb-accordion-item-header>
                        <nb-accordion-item-body>
                            <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>If the Convocation is not over, you can lodge an FIR reporting the loss of
                                            provisional
                                            certificate, get a receipt for the FIR and again apply to the University
                                            with the
                                            requisite fee and copy of the receipt of FIR.</p>
                                    </form>
                                </nb-card-body>
                            </nb-card>
                        </nb-accordion-item-body>
                    </nb-accordion-item>
                </nb-accordion>
                <br>
                <nb-accordion type="info">
                    <nb-accordion-item expanded=false>
                        <nb-accordion-item-header>Is provisional certificate and Marksheet
                            same?</nb-accordion-item-header>
                        <nb-accordion-item-body>
                            <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>No, they are different. Passing certificate is given to: (i) A candidate who
                                            has appeared
                                            in an examination of the Board shall be issued a statement of marks/grades.
                                        </p>
                                    </form>
                                </nb-card-body>
                            </nb-card>
                        </nb-accordion-item-body>
                    </nb-accordion-item>
                </nb-accordion>
                <br>
                <nb-accordion type="info">
                    <nb-accordion-item expanded=false>
                        <nb-accordion-item-header>Is provisional certificate and transfer certificate
                            same?</nb-accordion-item-header>
                        <nb-accordion-item-body>
                            <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>At last, we can say that a Provisional certificate is a temporary certificate
                                            which is given
                                            to the candidates to go for their higher studies or for a job while a
                                            Migration certificate is
                                            given to a candidate to continue their higher study or to change any
                                            university in
                                            between the course.</p>
                                    </form>
                                </nb-card-body>
                            </nb-card>
                        </nb-accordion-item-body>
                    </nb-accordion-item>
                </nb-accordion>
                <br>
                <nb-accordion type="info">
                    <nb-accordion-item expanded=false>
                        <nb-accordion-item-header>Is provisional certificate and passing certificate
                            same?</nb-accordion-item-header>
                        <nb-accordion-item-body>
                            <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p></p>
                                    </form>
                                </nb-card-body>
                            </nb-card>
                        </nb-accordion-item-body>
                    </nb-accordion-item>
                </nb-accordion>
                <br>
                <nb-accordion type="info">
                    <nb-accordion-item expanded=false>
                        <nb-accordion-item-header>Is provisional certificate and transcript
                            same?</nb-accordion-item-header>
                        <nb-accordion-item-body>
                            <nb-card>
                                <nb-card-body>
                                    <form>
                                        <p>This is used because the degree is not received yet. On the other
                                            hand, transcript
                                            certificate is like a marks certificate. Only the provisional certificate
                                            has to be
                                            submitted if asked for, nothing in place of that. Hope this helps.</p>
                                    </form>
                                </nb-card-body>
                            </nb-card>
                        </nb-accordion-item-body>

                    </nb-accordion-item>
                </nb-accordion>
                <br>
                <nb-accordion type="info">
                    <nb-accordion-item expanded=false>
                        <nb-accordion-item-header>What are the fees for Provisional Certificate
                            ?</nb-accordion-item-header>
                        <nb-accordion-item-body>
                            <nb-card>

                                <nb-card-body>
                                    <form>
                                        <p></p>
                                    </form>
                                </nb-card-body>
                            </nb-card>
                        </nb-accordion-item-body>

                    </nb-accordion-item>
                </nb-accordion>
                <br>
                <nb-accordion type="info">
                    <nb-accordion-item expanded=false>
                        <nb-accordion-item-header>Can We get the refunds, if the application is rejected ?
                            ?</nb-accordion-item-header>
                        <nb-accordion-item-body>
                            <nb-card>

                                <nb-card-body>
                                    <form>
                                        <p>
                                            No, the refunds are not applicable in any of the cases, if the application is
                                            rejected, then you can check with the university for the reson.
                                        </p>
                                    </form>
                                </nb-card-body>
                            </nb-card>
                        </nb-accordion-item-body>

                    </nb-accordion-item>
                </nb-accordion>
            </form>
        </div>
    </nb-card-body>
</nb-card>