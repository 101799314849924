import { Component, OnInit, Input, Optional } from '@angular/core';
import { ApplicationApi } from '../../../@core/backend/common/api/application.api';
import { Router, ActivatedRoute } from '@angular/router';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { UserStore } from '../../../@core/stores/user.store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User, UserData } from '../../../@core/interfaces/common/users';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { NetworkService } from '../../../@core/utils';
@Component({
  selector: 'ngx-admin-otp',
  templateUrl: './admin-otp.component.html',
  styleUrls: ['./admin-otp.component.scss']
})
export class AdminOtpComponent {
  @Input() value: string;

  @Input() email: string;
  @Input() user_id;
  otp: any;
  alert: any;
  alertflag: number;
  enterOtp: any;
  otpValidation: boolean;
  userId: string;
  id: String;
  protected readonly unsubscribe$ = new Subject<void>();
  user: any;
  role: any;
  // email: string;
  // value: string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ApplicationApi,
    private authService: NbAuthService,
    private usersService: UserData,
    private userStore: UserStore,
    protected network: NetworkService,
    @Optional() protected ref: NbDialogRef<AdminOtpComponent>) {
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {

      });

    this.userId = this.route.snapshot.queryParamMap.get('userId');


  }

  ngOnInit() {

    var det = this.network.getMachinId(this.user_id);

    // 

    if (this.value == 'admin' || this.value == 'sub-admin') {

      this.api.sendOtpStudent(this.email).subscribe(data => {
        if (data['status'] == 200) {
          this.otp = data['data']
        }
      })
    } else if (this.value == 'student') {
      this.api.sendOtpStudent(this.email).subscribe(data => {
        if (data['status'] == 200) {
          this.otp = data['data']
        }
      })
    }


  }

  cancel() {
    if (this.value == 'student') {
      this.ref.close();
      //  this.router.navigate(['pages/dashboard']);
      this.router.navigate(['auth/logout']);
    } else {
      this.router.navigate(['auth/logout']);
    }

  }


  resendOtp() {
    if (this.value != null) {

      this.api.resendOtp(this.email).subscribe(data => {
        if (data['status'] == 200) {
          this.otp = data['data']
        }
      })
    } else {
      this.api.resendOtp(this.email).subscribe(data => {
        if (data['status'] == 200) {
          this.otp = data['data']
        }
      })
    }
  }
  // verify(){
  //   // this.enterOtp = (document.getElementById('enterOtp') as HTMLInputElement).value;
  //   // if(this.otp === this.enterOtp){  
  //       this.api.updateOtp(thi).subscribe(data=>{
  //         if(data['status'] == 200){
  //           //this.router.navigate(['pages/admin-dashboard'])
  //           this.router.navigate(['pages/dashboard'])
  //         }
  //       })       
  //   // }else{
  //   //   this.otpValidation = false;

  //   // }
  // }
  verify() {
    this.enterOtp = (document.getElementById('enterOtp') as HTMLInputElement).value;

      
      this.api.updateOtp(this.email, this.enterOtp).subscribe(data => {
        
        if (data['status'] == 200) {

          if (this.value == 'student') {
            this.router.navigate(['/auth/login']);
            this.ref.close();
            // this.router.navigate(['auth/login']);
          } else {
            this.ref.close();

            this.router.navigate(['pages/dashboard']);
          }

        }
        else{
          this.router.navigate(['auth/logout']);
        }
      })
    // }
  }

}
