import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HttpService } from './http.service';
import 'rxjs/add/operator/map';



@Injectable()
export class ApplicationApi {
  addThirdPartyApplications(agentData: { firstname: any; lastname: any; seat: any; semester: any; Document: any; college: any; branch: any; passing: any; stream: any; }) {
    throw new Error('Method not implemented.');
  }
  private readonly apiController: string = 'application';

  constructor(private api: HttpService,
    private http: HttpClient) { }
  get(url: string): Observable<any> {
    return new Observable((observer: Subscriber<any>) => {
      let objectUrl: string = null;
      this.http
        .get(url, {
          responseType: 'blob'
        })
        .subscribe(m => {
          objectUrl = URL.createObjectURL(m);
          observer.next(objectUrl);
        });

      return () => {
        if (objectUrl) {
          URL.revokeObjectURL(objectUrl);
          objectUrl = null;
        }
      };
    });
  }
  addApplication(studentData, id, errata, app_id) {


    return this.http.post(`${environment.apiUrl}/addApplication`, { "studentData": studentData, "userId": id, "errata": errata, "app_id": app_id })
  }
  appliedForlanguage(value) {
    return this.http.post(`${environment.apiUrl}/addlanguage`, { "value": value })
  }
  addApplicationmaster(studentDatamaster: {
    seatno: any; passing_month: any; result: any; exam_pattern: any; college: any; reason: any; passing_year: any;
  }, id, errata) {
    return this.http.post(`${environment.apiUrl}/addApplicationmaster`, { "studentDatamaster": studentDatamaster, "userId": id, "errata": errata })
  }

  addAppliedFor(user, newapp, degree_type) {


    return this.http.post(`${environment.apiUrl}/addAppliedFor `, { "user": user, "newapp": newapp, "degree_type": degree_type })
  }


  CheckDegree(value, userId, newapp) {
    return this.http.get(`${environment.apiUrl}/CheckDegree?value=${value}&userId=${userId}&newapp=${newapp}`);
  }
  getamount(type, website) {
    return this.http.get(`${environment.apiUrl}/getamount?type=${type}&website=${website}`);

  }
  deleteDocument(doc_id, type) {
    return this.http.post(`${environment.apiUrl}/deleteDocument`, { "doc_id": doc_id, "type": type })
  }
  deletefile(doc_id, name) {

    return this.http.post(`${environment.apiUrl}/deletefile`, { "doc_id": doc_id, "name": name })
  }
  deletefileadmin(doc_id, name, userId, email_admin) {


    return this.http.post(`${environment.apiUrl}/deletefileadmin`, { "doc_id": doc_id, "name": name, "userId": userId, "email_admin": email_admin })
  }
  DeliveryDetails(data, user_id, newapp) {
    return this.http.post(`${environment.apiUrl}/SaveDeliveryDetails`, { "data": data, "user_id": user_id, "newapp": newapp })
  }
  // checkstepper
  getcheckstepper(editFlag, app_id, userId, newapp) {

    return this.http.get(`${environment.apiUrl}/CheckStepper?editFlag=${editFlag}&app_id=${app_id}&userId=${userId}&newapp=${newapp}`);

  }
  checkAppId(userId) {
    return this.http.get(`${environment.apiUrl}/checkAppId?userId=${userId}`);

  }

  getDocuments(userId) {
    return this.http.get(`${environment.apiUrl}/getDocuments?userId=${userId}`);

  }
  getDocumentsAdmin(userId, viewFrom) {
    console.log("viewFrom", viewFrom);

    return this.http.get(`${environment.apiUrl}/getDocuments_Admin?userId=${userId}&viewFrom=${viewFrom}`);

  }

  updateApplication(id, data, emails: Set<any>) {
    return this.http.post(`${environment.apiUrl}/updateApplication`, { "id": id, "data": data, "emails": Array.from(emails) })
  }
  downloadDocument(file, id): Observable<Blob> {

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.apiUrl}/downloadDocument?file=${file}&id=${id}`, { headers: headers, responseType: 'blob' }).map(
      (res) => {
        return new Blob([res], { type: 'application/pdf' });
      });
  }


  getApplication(id) {
    return this.http.get(`${environment.apiUrl}/getApplication?id=` + id)
  }

  getFacultyList() {
    return this.http.get(`${environment.apiUrl}/getStreamData`);
  }

  getCollegeList() {
    return this.http.get(`${environment.apiUrl}/getCollegeList`);
  }
  getfaculityList() {


    return this.http.get(`${environment.apiUrl}/getfaculityList`);
  }
  facultysub(sub) {


    return this.http.get(`${environment.apiUrl}/facultysub?sub=${sub}`);
  }
  getSubject(applyfor) {
    return this.http.get(`${environment.apiUrl}/getSubject?applyfor=${applyfor}`);
  }
  getSecondStepDetails(degreeName, user_id) {
    return this.http.get(`${environment.apiUrl}/getSecondStepDetails?degreeName=${degreeName}&user_id=${user_id}`);
  }


  getFirstStepDetails(id, value, editFlag, app_id) {

    return this.http.get(`${environment.apiUrl}/getFirstStepDetails?id=${id}&value=${value}&editFlag${editFlag}&app_id${app_id}`)
  }
  getalldata(newapp, editFlag, app_id) {

    return this.http.get(`${environment.apiUrl}/getalldata?value=${newapp}&editFlag=${editFlag}&app_id=${app_id}`)
  }
  getalldata1(newapp, editFlag, app_id) {

    return this.http.get(`${environment.apiUrl}/getalldata1?value=${newapp}&editFlag=${editFlag}&app_id=${app_id}`)
  }

  forgotpasss(email) {

    return this.http.post(`${environment.apiUrl}/forgotpasss`, { email: email })
  }
  addProfileData(addProfile) {


    return this.http.post(`${environment.apiUrl}/updateuser`, { addProfile: addProfile })
  }
  emailValues(data) {
    try {
      return this.http.post(`${environment.apiUrl}/checkEmail`, { data: data });
    } catch (error) {
    }
  }
  getpagedata() {

    return this.http.get(`${environment.apiUrl}/getpagedata`)
  }
  getedu() {

    return this.http.get(`${environment.apiUrl}/getedu`)
  }
  Program_List() {

    return this.http.get(`${environment.apiUrl}/Program_List`)
  }
  getdoc(id, app_id, editFlag) {

    return this.http.get(`${environment.apiUrl}/getdoc?id=${id}&app_id=${app_id}&editFlag=${editFlag}`)
  }
  viewdocument(id) {

    return this.http.get(`${environment.apiUrl}/viewdocument?id=${id}`)
  }
  getpulldata() {

    return this.http.get(`${environment.apiUrl}/getpulldata`)
  }


  checkIfErrata(app_id) {
    return this.http.get(`${environment.apiUrl}/checkIfErrata?app_id=${app_id}`)
  }
  getApplicationdata() {
    return this.http.get(`${environment.apiUrl}/getApplicationdata`)
  }


  checkErrata_Students(id) {

    return this.http.get(`${environment.apiUrl}/checkErrata_Students?id=` + id)
  }
  getUserDetails() {

    return this.http.get(`${environment.apiUrl}/getUserDetails`)
  }

  // to change password
  resetPasswordValues(data, email) {



    try {

      return this.http.post(`${environment.apiUrl}/resetpasswords`, {
        'data': data, "email": email
      });
    } catch (error) {


    }
  }
  getAdminSideDetails(id) {

    return this.http.get(`${environment.apiUrl}/getAdminSideDetails?id=` + id)
  }
  getAdminSideEducational_Details(id, app_id, viewFrom) {


    return this.http.get(`${environment.apiUrl}/getAdminSideEducational_Details?id=${id}&app_id=${app_id}&viewForm=${viewFrom}`)
  }
  getapplication(id) {

    return this.http.get(`${environment.apiUrl}/getapplication?id=` + id)
  }

  admin_pass_reset(user_mail) {
    return this.http.post(`${environment.apiUrl}/adminResetPassword`, { data: user_mail });

  }
  setErrata(app_id, notes, docid, type, email_admin, userId) {



    return this.http.post(`${environment.apiUrl}/setErrata`, { 'app_id': app_id, 'notes': notes, 'docid': docid, 'type': type, 'email_admin': email_admin, 'userId': userId });

  }


  admin_document_reset(userID) {
    return this.http.delete(`${environment.apiUrl}/admin_document_reset?userID=${userID}`);

  }
  getLastStepDetails(id) {
    return this.http.get(`${environment.apiUrl}/getLastStepDetails?id=` + id)
  }
  Otpvalue(data) {
    return this.http.post(`${environment.apiUrl}/api/auth/verify-otp-reg`, {
      data: data
    });

  }


  editEductaionalDetails(value, userId, form, email_admin, app_id) {
    return this.http.post(`${environment.apiUrl}/editEductaionalDetails`, { "value": value, "userId": userId, "form": form, "email_admin": email_admin, "app_id": app_id });
  }
  paymentrequests(amount, id, userdata, type, address_id, addresss, website, EducationalFormData) {


    return this.http.post(`${environment.apiUrl}/payment/paymentrequest`, { "amount": amount, "id": id, "userdata": userdata, "type": type, "address_id": address_id, "addresss": addresss, "website": website, "EducationalFormData": EducationalFormData });
  }

  // to save notes
  saveNotes(id, notes) {
    return this.http.post(`${environment.apiUrl}/saveNotes`, { id: id, notes: notes });
  }


  //to get all the students in student dashboard
  getallstudents(page, name, email) {
    return this.http.get(`${environment.apiUrl}/students?page=${page}&name=${name}&email=${email}`);
  }

  //to get all the "paid" students in student dashboard
  getAll_PaidApplications(page, id, name, email, Order, enrollNo) {
    return this.http.get(`${environment.apiUrl}/paid_students?page=${page}&id=${id}&name=${name}&email=${email}&Order=${Order}&enrollNo=${enrollNo}`);
  }

  //to get all the "print" students in student dashboard
  getAll_PrintApplications(page, id, name, email, tab, enrollNo) {


    return this.http.get(`${environment.apiUrl}/print_students?page=${page}&id=${id}&name=${name}&email=${email}&tab=${tab}&enrollNo=${enrollNo}`);
  }

  //to get all the "pending" students in student dashboard
  getAll_PendingApplications(page, id, name, email, enrollNo) {


    return this.http.get(`${environment.apiUrl}/pending_students?page=${page}&id=${id}&name=${name}&email=${email}&enrollNo=${enrollNo}`);
  }

  //to get all the "requested" students in student dashboard
  getAll_RequestedApplications(page, id, name, email, enrollNo) {
    return this.http.get(`${environment.apiUrl}/requested_students?page=${page}&id=${id}&name=${name}&email=${email}&enrollNo=${enrollNo}`);
  }
  //to get all the "changed" students in student dashboard
  getAll_ChangedApplications(page, id, name, email, enrollNo) {


    return this.http.get(`${environment.apiUrl}/changed_students?page=${page}&id=${id}&name=${name}&email=${email}&enrollNo=${enrollNo}`);
  }

  getAll_RejectedApplications(page, id, name, email, enrollNo) {
    return this.http.get(`${environment.apiUrl}/rejected_student?page=${page}&id=${id}&name=${name}&email=${email}&enrollNo=${enrollNo}`)
  }

  // to get the verified applications
  getAll_verifiedApplications(page, id, name, email, enrollNo) {


    return this.http.get(`${environment.apiUrl}/verified_students?page=${page}&id=${id}&name=${name}&email=${email}&enrollNo=${enrollNo}`);
  }

  // to get the signed applications
  getAll_signedApplications(page, name, email) {
    return this.http.get(`${environment.apiUrl}/signed_students?page=${page}&name=${name}&email=${email}`);
  }
  // to get the notes of the applicant
  getNotes(id, user_id) {
    return this.http.get(`${environment.apiUrl}/getnotes?id=${id}&user_id=${user_id}`);
  }

  // to get the emailed applications
  getAll_emailedApplications(page, id, name, email, enrollNo) {
    return this.http.get(`${environment.apiUrl}/emailed_students?page=${page}&id=${id}&name=${name}&email=${email}&enrollNo=${enrollNo}`);
  }
  // // set the tracker as verified  from pending
  setVerified(app_id, value, user_id, email_admin) {
    return this.http.post(`${environment.apiUrl}/setVerified`, { "app_id": app_id, "value": value, "user_id": user_id, "email_admin": email_admin });
  }

  // to generate the certificate from verifeid
  generateCertificate(userId, app_id, print_date, email_admin) {


    return this.http.post(`${environment.apiUrl}/generateCertificate`, { "userId": userId, "app_id": app_id, "print_date": print_date, "email_admin": email_admin });
  }
  // //to get all the "paid" students in student dashboard
  // getAll_PendingApplications(page,name,email){
  //   return this.http.get(`${environment.apiUrl}/paid_students?page=${page}&name=${name}&email=${email}`);
  // }


  //Get IP Adress using http://freegeoip.net/json/?callback
  getIpAddress() {
    return this.http
      .get('https://ipapi.co/json/')
      // .get('http://ip-api.com/json')
      .map(response => response || {})
  }

  // admin-dashboard

  sendOtp(email) {
    return this.http.get(`${environment.apiUrl}/get_otp?email=` + email);
  }
  updateOtp(email, otp) {
    return this.http.get(`${environment.apiUrl}/update_otp?email=` + email + `&otp=` + otp);


  }
  UpdateNumberOTP(data) {
    return this.http.post(`${environment.apiUrl}/api/auth/resend-otp`, {
      data: data
    });

  }
  getApplicationIdWise(id) {
    return this.http.get(`${environment.apiUrl}/getApplicationIdWise?id=` + id)
  }
  getApplications() {
    return this.http.get(`${environment.apiUrl}/getApplications`)
  }
  getProcessedApplications() {
    return this.http.get(`${environment.apiUrl}/getProcessedApplications`)
  }
  getErrataApplications() {
    return this.http.get(`${environment.apiUrl}/getErrataApplications`)
  }
  getAgents() {
    return this.http.get(`${environment.apiUrl}/getAgents`)
  }

  checkApplication(email) {
    return this.http.get(`${environment.apiUrl}/checkApplication?email=${email}`);
  }

  // payment(app_id, app_name, app_email, amount){
  //   return this.http.post(`${environment.apiUrl}/paymentrequest`, {"app_id":app_id,"app_email":app_email, "amount":amount, "app_name":app_name})
  // }

  PaymentDetails(order_id) {
    return this.http.post(`${environment.apiUrl}/PaymentDetails`, { "order_id": order_id })
  }
  OnlinePaymentChallan(user_id, payment_amount, payment_status, application_id, payment_date_time, order_id, transaction_id) {
    try {
      return this.http.post(`${environment.apiUrl}/OnlinePaymentChallan`, { "user_id": user_id, "payment_amount": payment_amount, "transaction_id": transaction_id, "payment_date_time": payment_date_time, "status_payment": payment_status, "application_id": application_id, "order_id": order_id });
    } catch (error) {
      // this.handleError("OnlinePaymentChallan : "+error);
    }
  }
  downloadpay(pdf): Observable<Blob> {


    try {
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      return this.http.get(`${environment.apiUrl}/downloadpayment?pdf=` + pdf, { headers: headers, responseType: 'blob' }).map(
        (res) => {
          return new Blob([res], { type: 'application/pdf' });
        });
    } catch (error) {
      // this.handleError("unable to get Files : "+JSON.stringify(error));
    }
  }
  processApplication(id) {
    return this.http.post(`${environment.apiUrl}/processApplication`, { "id": id })
  }
  errataApplication(id, userId, errataMesg, first_name, last_name, choose_file, lastErrata) {
    return this.http.post(`${environment.apiUrl}/errataApplication`, {
      "id": id, "userId": userId, "errataMesg": errataMesg, "first_name": first_name,
      "last_name": last_name, "choose_file": choose_file, "lastErrata": lastErrata
    })
  }
  viewDocument(url) {
    return this.http.get(url)
  }
  generatepdfform(userId, app_id) {
    return this.http.post(`${environment.apiUrl}/generatepdfform`, { "userId": userId, "app_id": app_id })
  }
  downloadFiles(pdf): Observable<Blob> {
    try {
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      return this.http.get(`${environment.apiUrl}/download?pdf=` + pdf, { headers: headers, responseType: 'blob' }).map(
        (res) => {
          return new Blob([res], { type: 'application/pdf' });
        });
    } catch (error) {
      // this.handleError("unable to get Files : "+JSON.stringify(error));
    }
  }
  feedBack(satisfy, recommend, staff, experience, exp_prob, suggestion) {




    try {
      return this.http.post(`${environment.apiUrl}/feedBack `, { "satisfy": satisfy, "recommend": recommend, "staff": staff, "experience": experience, "exp_prob": exp_prob, "suggestion": suggestion });
    } catch (error) {
    }
  }
  addAlternateAddress(newAddress, id, address_id) {
    try {
      return this.http.post(`${environment.apiUrl}/addAlternateAddress`, { 'address': newAddress, 'user_id': id, "address_id": address_id })
    } catch (error) {

    }
  }

  getOtherAddress(user_id, address_id) {
    try {
      return this.http.get(`${environment.apiUrl}/getOtherAddress?user_id=` + user_id + `&address_id=` + address_id)
    } catch (error) {

    }
  }

  MergeDocuments(app_id, user_id) {

    try {
      return this.http.get(`${environment.apiUrl}/mergeAllUserDocuments?app_id=` + app_id + `&user_id=` + user_id)
    } catch (error) {

    }
  }
  getfeedback() {


    return this.http.get(`${environment.apiUrl}/getfeedback`);
  }

  checkPinValues(data) {
    try {
      return this.http.post(`${environment.apiUrl}/checkPinValues`, { data: data });
    } catch (error) {

    }
  }
  getAddressAdmin(user_id, id) {

    return this.http.get(`${environment.apiUrl}/getAddressAdmin?id=${id}&user_id=${user_id}`);
  }
  downloadExcel(type) {
    try {
      return this.http.get(`${environment.apiUrl}/downloadExcel?type=` + type)
    } catch (error) {
      this.handleError(" downloadExcel : " + JSON.stringify(error))
    }
  }
  mail(user_id, id, email, file_path, email_admin) {

    try {
      return this.http.post(`${environment.apiUrl}/mail`, { "user_id": user_id, "id": id, "email": email, "file_path": file_path, "email_admin": email_admin })
    } catch (error) {
      this.handleError(" downloadExcel : " + JSON.stringify(error))
    }
  }
  colornagnge(value, app_id) {
    try {
      return this.http.post(`${environment.apiUrl}/colornagnge`, { "value": value, "app_id": app_id, })
    } catch (error) {
      this.handleError(" downloadExcel : " + JSON.stringify(error))
    }
  }
  handleError(arg0: string) {
    throw new Error('Method not implemented.');
  }

  getedudata() {

    return this.http.get(`${environment.apiUrl}/getedudata`)
  }
  mergecerti(arr) {


    return this.http.post(`${environment.apiUrl}/mergecerti`, { arr: arr });

  }
  mergemark(arr) {


    return this.http.post(`${environment.apiUrl}/mergemark`, { arr: arr });

  }
  regeneratePdf(appl_id, user_id, reg_reason, email_admin) {


    try {
      return this.http.get(`${environment.apiUrl}/regeneratePdf?user_id=${user_id}&appl_id=${appl_id}&reg_reason=${reg_reason}&email_admin=${email_admin}`);
    } catch (error) {
      this.handleError("regeneratePdf : " + JSON.stringify(error));
    }
  }
  sentoedulab(appl_id, user_id, trackingid, post, date, email_admin) {


    try {
      return this.http.post(`${environment.apiUrl}/sentoedulab`, { "appl_id": appl_id, "user_id": user_id, "trackingid": trackingid, "post": post, "date": date, "email_admin": email_admin })

    } catch (error) {
      this.handleError("regeneratePdf : " + JSON.stringify(error));
    }
  }

  savetrackingdetails(appl_id, user_id, trackingid, post, date, email_admin) {


    try {
      return this.http.post(`${environment.apiUrl}/savetrackingdetails`, { "appl_id": appl_id, "user_id": user_id, "trackingid": trackingid, "post": post, "date": date, "email_admin": email_admin })

    } catch (error) {
      this.handleError("regeneratePdf : " + JSON.stringify(error));
    }
  }
  // downloadPhoto(formpath):Observable<Blob>{

  //   try {
  //     let headers = new HttpHeaders();
  //     headers.append('Content-Type','application/json');
  //       return this.httpClient.get(`${environment.apiUrl}/downloadphotofile?formpath=${formpath}`, { headers: headers, responseType: 'blob'}).map(
  //         (res) => {
  //           

  //           return new Blob([res], { type: 'application/pdf' });
  //         });

  //   }
  //    catch (error) {
  //     this.handleError("unable to get the Files :"+JSON.stringify(error))
  //   }
  // }

  setpaymentissuedetails(data, user_id) {



    try {
      return this.http.post(`${environment.apiUrl}/setPaymentIssueDetails?user_id=${user_id}`, { "data": data, });


    } catch (error) {
      //this.handleError("setPaymentIssueDetails : "+error);
    }
  }

  getPaymentIssueDetails() {


    try {
      return this.http.get(`${environment.apiUrl}/getpaymentIssuedetails`);
    }
    catch (err) {



    }
  }

  deletecourse(data) {
    // 
    try {
      return this.http.post(`${environment.apiUrl}/DeleteCourse`, { data: data });

    }
    catch (err) {

    }

  }
  returnaddpication(studentData, app_id) {
    return this.http.post(`${environment.apiUrl}/returnaddpication`, { "studentData": studentData, "app_id": app_id })
  }
  secondpaymentrequests(amount, id, userdata, type, addresssobj, edudata, app_id) {


    return this.http.post(`${environment.apiUrl}/payment/secondpaymentrequests`, { "amount": amount, "id": id, "userdata": userdata, "type": type, "addresssobj": addresssobj, "edudata": edudata, "app_id": app_id });
  }
  getamountreturn(type) {
    return this.http.get(`${environment.apiUrl}/getamountreturn?type=${type}`);

  }
  downloadFilesExcel(pdf): Observable<Blob> {
    try {
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      return this.http.get(`${environment.apiUrl}/download?pdf=` + pdf, { headers: headers, responseType: 'blob' }).map(
        (res) => {
          return new Blob([res], { type: 'application/pdf' });
        });
    } catch (error) {

    }
  }
  getCourses() {
    try {
      return this.http.get(`${environment.apiUrl}/getCourses`);
    } catch (error) {

    }
  }
  savereturn(EducationalFormData, app_id) {


    try {
      return this.http.post(`${environment.apiUrl}/savereturn `, { "EducationalFormData": EducationalFormData, "app_id": app_id });
    } catch (error) {
    }
  }
  getreturndata() {
    return this.http.get(`${environment.apiUrl}/getreturndata`);

  }
  activitytracker(page, date, email) {
    try {
      return this.http.get(`${environment.apiUrl}/activitytracker?page=${page}&date=${date}&email=${email}`);
    } catch (error) {
      // this.handleError("activitytracker : "+JSON.stringify(error));
    }
  }

  getAll_feedback(page, name, email) {
    return this.http.get(`${environment.apiUrl}/feedback_students?page=${page}&name=${name}&email=${email}`);
  }
  getCollegeData(college_name) {
    try {
      return this.http.get(`${environment.apiUrl}/getCollegeData?college_name=${college_name}`);
    } catch (error) {
      //this.handleError("getCollegeData : "+JSON.stringify(error));
    }
  }
  updateCollegeStatus(status, college_id, college_name) {
    try {
      return this.http.post(`${environment.apiUrl}/updateCollegeStatus`, { "college_id": college_id, "college_status": status, "college_name": college_name })
    } catch (error) {
      // this.handleError("updateCollegeStatus : "+JSON.stringify(error));
    }
  }
  addOrUpdateCollegeData2(data, adminemail) {
    try {
      return this.http.post(`${environment.apiUrl}/addOrUpdateCollegeData`, { "collegeData": data, "adminemail": adminemail })
    } catch (error) {
      // this.handleError("addOrUpdateCollegeData : "+JSON.stringify(error));
    }
  }
  sendEmailToCollege(collegeId, user_id) {
    try {
      return this.http.post(`${environment.apiUrl}/sendEmailToCollege`, { "collegeId": collegeId, "user_id": user_id });
    } catch (error) {
      //this.handleError("deleteNotification : "+error);
    }
  }


  damagedcertificatedetails(data, user_id) {

    try {
      return this.http.post(`${environment.apiUrl}/damagedcertificatedetail?user_id=${user_id}`, { "data": data });

    }
    catch (err) {

    }
  }


  getincorrectcertificate() {

    try {
      return this.http.get(`${environment.apiUrl}/getincorrectcertificate`);
    }
    catch (err) {


    }

  }

  deletedamageDocument(id, type) {
    try {
      return this.http.delete(`${environment.apiUrl}/deletedamageDocument?id=${id}&type=${type}`);

    } catch (error) {
      //this.handleError("deleteDocument: "+JSON.stringify(error));
    }
  }


  deletepaymenterror(id, type) {
    try {
      return this.http.delete(`${environment.apiUrl}/deletepaymenterrordocument?id=${id}&type=${type}`);
    }
    catch (err) {


    }
  }
  addupdateRole(roles, user_id) {
    return this.http.post(`${environment.apiUrl}/application/role_management/setUpdateRole`, { "roles": roles, "user_id": user_id });

  }

  getMenuRole(userID) {


    return this.http.get(`${environment.apiUrl}/role_management/getMenuRole?userID=` + userID);
  }
  getSubAdminData(userId) {


    return this.http.get(`${environment.apiUrl}/role_management/getSubAdminData?userId=` + userId);

  }
  getEnrollmentData(enrollment) {


    //return this.http.post(`${environment.apiUrl}/getEnrollmentData?enrollment=${enrollment}`);
    return this.http.post(`${environment.apiUrl}/getEnrollmentData`, { "enrollment": enrollment });

  }

  addUpdatesubAdmin(userId, subAdminData) {

    return this.http.post(`${environment.apiUrl}/role_management/addUpdatesubAdmin`, { "userId": userId, "subAdminData": subAdminData });

  }


  getRolesData(userId) {
    return this.http.get(`${environment.apiUrl}/application/role_management/getRolesData?userId=` + userId);

  }
  // studentnotp
  sendOtpStudent(email) {



    return this.http.get(`${environment.apiUrl}/get_otp_student?email=${email}`);
  }
  getotp(email) {



    return this.http.get(`${environment.apiUrl}/getotp?email=${email}`);
  }
  resendOtp(email) {

    return this.http.get(`${environment.apiUrl}/get_otp_resend?email=${email}`);
  }
  checkenroll(value, course) {
    return this.http.get(`${environment.apiUrl}/checkenroll?value=${value}&course=${course}`);
  }
  changeSubAdminStatus(userId) {
    return this.http.post(`${environment.apiUrl}/application/role_management/changeSubAdminStatus`, { "userId": userId });
  }
  setUserProfile(userId, data, adminemail, app_id) {

    return this.http.post(`${environment.apiUrl}/editpeofile`, { "userId": userId, "data": data, "adminemail": adminemail, "app_id": app_id })
  }

  getPaymentDetails(tab_type) {


    try {
      return this.http.get(`${environment.apiUrl}/getPaymentDetails?tab_type=` + tab_type);
    } catch (error) {
      //  this.handleError("getPaymentDetails : "+JSON.stringify(error));
    }
  }

  getallpedingpayment(page, date, email, value, adminemail) {



    try {
      return this.http.get(`${environment.apiUrl}/getallpedingpayment?page=${page}&date=${date}&email=${email}&value=${value}&adminemail=${adminemail}`);
    } catch (error) {
      // this.handleError("activitytracker : "+JSON.stringify(error));
    }
  }
  getallresolvedgpayment(page, date, email, value, adminemail) {
    try {
      return this.http.get(`${environment.apiUrl}/getallresolvedpayment?page=${page}&date=${date}&email=${email}&value=${value}&adminemail=${adminemail}`);
    } catch (error) {
      // this.handleError("activitytracker : "+JSON.stringify(error));
    }
  }

  setResolve(id, email, app_id, adminemail, value) {
    return this.http.post(`${environment.apiUrl}/setResolve`, { "id": id, "email": email, "app_id": app_id, "adminemail": adminemail, "value": value });
  }

  getAll_Paydetails(page, id, name, email, order_id, bank_ref_no) {


    return this.http.get(`${environment.apiUrl}/getAll_Paydetails?page=${page}&id=${id}&name=${name}&email=${email}&order_id=${order_id}&bank_ref_no=${bank_ref_no}`);
  }

  downloadExcel_date(startDate, endDate) {


    try {
      return this.http.get(`${environment.apiUrl}/downloadExcel_date?startDate=` + startDate + '&endDate=' + endDate)
    } catch (error) {

    }
  }

  // download from my applications
  downloadFiles1(formPath): Observable<Blob> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${environment.apiUrl}/downloadFiles_demo?formPath=` + formPath, { headers: headers, responseType: 'blob' }).map(
      (res) => {
        return new Blob([res], { type: 'application/pdf' });
      });

  }

  DeliveryDetails2(data, user_id, newapp) {


    return this.http.post(`${environment.apiUrl}/SaveDeliveryDetails2`, { "data": data, "user_id": user_id, "newapp": newapp })
  }


  addCourseData(data) {

    try {
      return this.http.post(`${environment.apiUrl}/addCourseData`, { "courseData": data })
    } catch (error) {
      // this.handleError("addOrUpdateCollegeData : "+JSON.stringify(error));
    }
  }
  senttoprint(app_id, user_id) {

    try {
      return this.http.get(`${environment.apiUrl}/senttoprint?user_id=${user_id}&app_id=${app_id}`);
    } catch (error) {
      this.handleError("regeneratePdf : " + JSON.stringify(error));
    }
  }
  getAllEmailData(page, to_email, subject) {
    // 
    try {
      return this.http.get(`${environment.apiUrl}/getAllEmailActivityTracker?page=${page}&to_email=${to_email}&subject=${subject}`);
    } catch (err) {
      this.handleError("getAllEmailData :" + JSON.stringify(err));

    }
  }
  // sending from done application to print by edulab
  sendToPrintByEdulab(app_id, tracker) {
    try {
      return this.http.get(`${environment.apiUrl}/sendToPrintByEdulab?app_id=${app_id}&tracker=${tracker}`);
    } catch (error) {

    }
  }

  addtocart(applycourse) {
    return this.http.post(`${environment.apiUrl}/addtocart`, { "applycourse": applycourse })
  }

  getcartvalue() {

    return this.http.get(`${environment.apiUrl}/getcartvalue`);

  }
  roleManage(user_id) {

    try {

      return this.http.get(`${environment.apiUrl}/roleManage?user_id=${user_id}`);
    }
    catch (err) {

    }

  }
  VerifiedOtpManage() {

    try {
      return this.http.get(`${environment.apiUrl}/VerifiedOtpManage`)
    }
    catch (err) {

    }
  }

  addNotes(data: any) {

    try {

      return this.http.post(`${environment.apiUrl}/addNotes`, { "data": data })
    }
    catch (err) {

    }
  }
  getNote() {
    try {

      return this.http.get(`${environment.apiUrl}/getNote`)
    }
    catch (err) {

    }
  }

}