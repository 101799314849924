<nb-card>
  <nb-card-header status="success">
    <label style="font-size: 140%;">Verified Applications</label>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="searchForm" class="row">
      <div class="col-xl-4 form-group">
        <label>Application Id</label><br>
        <input nbInput formControlName="idCtrl" placeholder="Application Id"
          [ngClass]="{'form-control-danger': searchForm.controls.idCtrl.invalid && (searchForm.controls.idCtrl.dirty || searchForm.controls.idCtrl.touched)}">
      </div>
      <div class="col-xl-4 form-group">

        <label>Name</label><br>
        <input nbInput formControlName="nameCtrl" [(ngModel)]="name" placeholder="name"
          [ngClass]="{'form-control-danger': searchForm.controls.nameCtrl.invalid && (searchForm.controls.nameCtrl.dirty || searchForm.controls.nameCtrl.touched)}">
      </div>

      <div class="col-xl-4 form-group">
        <label>Email ID</label><br>
        <input nbInput formControlName="emailCtrl" [(ngModel)]="email" placeholder="email"
          [ngClass]="{'form-control-danger': searchForm.controls.emailCtrl.invalid && (searchForm.controls.emailCtrl.dirty || searchForm.controls.emailCtrl.touched)}">
      </div>

      <div class="col-xl-4 form-group">
        <label>Enroll No</label><br>
        <input nbInput formControlName="EnrollnoCtrl"  placeholder="Enrollno"
          [ngClass]="{'form-control-danger': searchForm.controls.EnrollnoCtrl.invalid && (searchForm.controls.EnrollnoCtrl.dirty || searchForm.controls.EnrollnoCtrl.touched)}">
      </div>

      <div class="col-xl-4 form-group">
        <button nbButton (click)="search()">Search</button>
      </div>
    </form>


    <br>
    <div class="row">
      <div class="col-xl-4 form-group">
          <input matInput type="text" [value]="filterText" [placeholder]="filterPlaceholder"
            [formControl]="filterInput" />
          <button mat-button *ngIf="filterText" matSuffix mat-icon-button aria-label="Clear" (click)="filterText=''">
            <mat-icon>close</mat-icon>
          </button>
        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
      </div>
      
    </div>
    <div class="wrapper">
      <h5><b>Total student count: {{studentLength?.totalLength}} </b></h5><br /><br />
      <!-- <h5><b>Filtered student count: </b></h5>{{studentdata?.length}} -->

      <table id="selectedColumn">
        <thead>
          <tr>
            <th>App_ID</th>
            <th>Enroll No</th>
            <th>Name As Per Marksheet</th>
            <th>Email ID</th>
            <th>Application Tracker</th>
            <th>Application Date</th>
            <th>Action</th>
            <th>Verify</th>
            <th>Note</th>
          </tr>
        </thead>
        <tbody>
          <tr
          *ngFor="let student of studentdata | filter:{name:filterText , email: filterText , id:filterText}  | paginate: { itemsPerPage: 10, totalItems: totalLength, currentPage: p }">
            <td>
              {{student?.id}}
            </td>
            <td>
              {{student?.enroll_no}}
            </td>
            <td>
              {{student?.fullname}}
            </td>
            <td>
              {{student?.email}}
            </td>
            <td>
              Approved by:{{student?.approved_by}}<br>Approved Date:{{student?.verified_date}}
            </td>
            <td>
              {{student?.created_at}}
            </td>
            <td>
              <i class="fa ion-person" status="info" nbPopover="View More" nbPopoverMode="hover"
                (click)="handleClick(student.user_id,student.id,'verified')"></i><br>
              <button nbButton outline size="small"
                *ngIf="student.otp_verify==0 && student.email_verification_token ==0" status="primary"
                (click)="verifyOtp('0', '0', student.id);">Verify</button>
              <button nbButton outline size="small" status="danger" *ngIf="student.userStatus == 'active'"
                (click)="status('inactive',student.id);">inactive</button>
              <button nbButton outline size="small" status="success" *ngIf="student.userStatus == 'inactive'"
                (click)="status('active',student.id);">active</button>
                <i class="fa ion-refresh" status="info" nbPopover="View More" nbPopoverMode="hover"
                (click)="BackToPending(student.id,student.user_id,'verified',email_admin)"></i>
            </td>
            <td>
              <button button class="btn btn-primary"
                (click)="Verify(student.user_id,student.id,email_admin)">Verify</button><br>
            </td>
            <td>
              <i class="fa ion-clipboard" status="info" nbPopover="View More" nbPopoverMode="hover"
                (click)="addNotes(student.id)"></i><br>
            </td>
          </tr>
        </tbody>
      </table>

      <div *ngIf="studentLength?.totalLength == 0">
        <span style="color : red; font-size: 18px;">No Data Available</span>
      </div>
    </div>
    <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
  </nb-card-body>
  <!-- <p-dialog [(visible)]="displayVideo2" [modal]="true" [responsive]="true" [style]="{width: '350px', minWidth: '200px'}">
      <nb-card style="width: 350px;min-width: 200px;">
        <nb-card-body id="cardbody1">
          <form [formGroup]="searchForm">
          <input nbInput placeholder="Pick Date"  [nbDatepicker]="dateTimePicker" formControlName="pickdatectrl" >
          <nb-datepicker #dateTimePicker></nb-datepicker><br>
          <button nbButton   status="primary" (click)="save()">Save</button>
        </form>
        </nb-card-body>
      </nb-card>
    </p-dialog> -->
</nb-card>