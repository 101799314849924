
export const environment = {
  production: true,
  apiUrl: 'https://devhsncpdc.studentscenter.in/api',
  uploadFile : 'https://devhsncpdc.studentscenter.in/api/markListUploadUrl',
  PaymentIssueUrl:'https://devhsncpdc.studentscenter.in/api/PaymentIssueUrl',
  courier_excel_sheets: 'https://devhsncpdc.studentscenter.in/api/courier_excel_sheets',
  Damageceritificate:'https://devhsncpdc.studentscenter.in/api/Damageceritificate',
  viewUrl: 'https://devhsncpdc.studentscenter.in/api/',
  uploadFiles: 'https://devhsncpdc.studentscenter.in/api/adminupload',
  adminnewupload:'https://devhsncpdc.studentscenter.in/api/adminnewupload',
  dbConfig :'/mnt/sda4/HSNC/pdc',

  testUser: {
    token: {},
    email: '',
  },
};
