import { Component, OnInit } from '@angular/core';
import { ApplicationApi } from '../../../@core/backend/common/api/application.api';
import { NbAuthJWTToken,NbAuthService } from '@nebular/auth';
import { Router } from '@angular/router';
// import { CountriesService } from '../../@core/data/countries.service';
// import { CountriesService } from '../../@core/data/countries.service';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
// import { Data } from '../../shared/data';
@Component({
  selector: 'ngx-admin-signed',
  templateUrl: './admin-signed.component.html',
  styleUrls: ['./admin-signed.component.scss']
})
export class AdminSignedComponent implements OnInit {
  Countries: any [];
  p: number = 1;
  public filterText: string;
  public filterPlaceholder: string;
  public filterInput = new FormControl();
  selectedYear ='2019'
  studentdata: any;
  active: any;
  adminemail: any;

  searchForm:FormGroup;
  name : string;
  email : string;
  isLoadingResults: boolean = false;
  studentLength : any;
  student_name: any;
  constructor(  private api: ApplicationApi,
    private authService : NbAuthService,
    private router : Router,
    // protected countries :CountriesService,
    public toasterService: NbToastrService,
    // protected globalVar:Data,
    private formBuilder:FormBuilder,) {

   }

  ngOnInit() {
    this.filterText = "";
    this.filterPlaceholder = "Local Search";
    this.refresh(null,null);
    this.searchForm = this.formBuilder.group({
      nameCtrl:[''],
      emailCtrl:['']
      // idCtrl : [''],
    })
  }

  handleClick(user_id,app_id) {

    this.router.navigate(['pages/adminView'],{queryParams:{userId : user_id, app_id : app_id, viewFrom : 'signed'}});
  }
  pageChanged(event){

  }

  search(){

  }
  refresh(student_name, email){
    this.student_name=student_name;
    this.isLoadingResults = true;
    // 
    this.api.getAll_signedApplications(this.p,student_name,email).subscribe(data =>{
      this.isLoadingResults = false;
      this.studentdata = data['items'];

      this.studentLength = data['total_count'];
      // this.active=data['counts'];
    })
    this.filterInput
      .valueChanges
      // .debounceTime(200)
      .subscribe(term => {
       this.filterText = term;
    });
  }

  sendEmail(userId,app_id){
    this.api.setVerified(app_id ,'signed',userId,this.adminemail).subscribe((data: any) => {
      if(data['status'] ==  200){
        this.toasterService.success('Email Sent');
        this.refresh(this.student_name, this.email);
      }
    })


  }
}
