import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HttpService } from './http.service';

@Injectable()
export class RegisterUserApi {
  private readonly apiController: string = 'registeruser';

  constructor(private api: HttpService,
    private http:HttpClient) { }

    getRegisterUser(){
      return this.http.get(`${environment.apiUrl}/getRegisterUser`)
    }

   

   

}