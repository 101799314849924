<nb-card>
  <nb-card-header status="success">
    <div class="row">
      <div class="col-xl-6 col-md-6 col-sm-6 col-6">
      <label style="font-size: 140%;">Print By Edulab </label>
      <div >
        <form [formGroup]="CourierUploadForm">
          <p-fileUpload name="file"
            url="{{serverUrl}}" 
            auto="true" (onUpload)="onUpload($event)"
            (onBeforeSend)="onBeforeSend($event)"
            maxFileSize="5000000" mode="basic"
            formControlName="financeUpload" chooseLabel="Upload Excel"
            ngDefaultControl>
          </p-fileUpload>
        </form>
        <button style="float: right;" nbButton status="success" (click)="downloadExcel('printedulab')"   >
          <div style="font-size: 86%;">
            Download Excel
          </div>   
               
        </button>
      </div>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="searchForm">
      <div class="row">
      <div class="col-md-4">
        <div class="col-md-4">
          <label>Application Id</label><br>
            <input nbInput formControlName="idCtrl" placeholder="Application Id" 
              [ngClass]="{'form-control-danger': searchForm.controls.idCtrl.invalid && (searchForm.controls.idCtrl.dirty || searchForm.controls.idCtrl.touched)}">
          </div>
        <div class="form-group">
          <label>Name</label><br>
          <input nbInput formControlName="nameCtrl"
          [(ngModel)]="name" placeholder="name"
          [ngClass]="{'form-control-danger': searchForm.controls.nameCtrl.invalid && (searchForm.controls.nameCtrl.dirty || searchForm.controls.nameCtrl.touched)}">
      </div>
      </div>
     

      <div class="col-md-4">
        <div class="form-group">
          <label>Email ID</label><br>
          <input nbInput formControlName="emailCtrl"
          [(ngModel)]="email" placeholder="email"
          [ngClass]="{'form-control-danger': searchForm.controls.emailCtrl.invalid && (searchForm.controls.emailCtrl.dirty || searchForm.controls.emailCtrl.touched)}">
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label>Enroll No</label><br>
          <input nbInput formControlName="enroll_noCtrl"
           placeholder="enroll_no"
          [ngClass]="{'form-control-danger': searchForm.controls.enroll_noCtrl.invalid && (searchForm.controls.enroll_noCtrl.dirty || searchForm.controls.enroll_noCtrl.touched)}">
        </div>
      </div>

      <div class="col-md-4">
        <button nbButton (click)="search()">Search</button>
      </div>
      </div>
    </form>


      <br>
    <div class="row">
        <div class="col-lg-6 ">
          <mat-form-field class="example-full-width">
            <input matInput type="text" [value]="filterText" [placeholder]="filterPlaceholder"
              [formControl]="filterInput" />
            <button mat-button *ngIf="filterText" matSuffix mat-icon-button aria-label="Clear" (click)="filterText=''">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
        </div>
        <!-- <div class="col-lg-3 ">
          <mat-form-field>
            <mat-select placeholder="select Year" [(ngModel)]="selectedYear" name="selectedYear" id="yearCtrl"
              (ngModelChange)="filterYear($event)">
              <mat-option value="2019">2019-2020</mat-option>
              <mat-option value="2020">2020-2021</mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
      </div>
    <div class="wrapper">
      <h5><b>Total student count: {{studentLength?.totalLength}} </b></h5><br/><br/>
      <!-- <h5><b>Filtered student count: </b></h5>{{studentdata?.length}} -->
      <div class="Rtable Rtable--5cols Rtable--collapse">
        <div class="Rtable-row Rtable-row--head">
          <div class="Rtable-cell date-cell column-heading">Application Id</div>
          <div class="Rtable-cell date-cell column-heading">Name As Per Marksheet</div>
          <div class="Rtable-cell topic-cell column-heading">Email Address</div>
          <div class="Rtable-cell date-cell column-heading">Application Tracker</div>
          <div class="Rtable-cell date-cell column-heading">Application Status</div>
          <div class="Rtable-cell date-cell column-heading">Application Date</div>
          <div class="Rtable-cell date-cell column-heading">Print Certificate</div>
          <div class="Rtable-cell date-cell column-heading">Print Address</div>
          <div class="Rtable-cell date-cell column-heading">Action</div>
      

          <!-- <div class="Rtable-cell date-cell column-heading">Note</div>
          <div class="Rtable-cell date-cell column-heading">Regenerate</div> -->
          <!-- <div class="Rtable-cell date-cell column-heading">Print Done</div> -->
       



        </div>
        
        <div class="Rtable-row is-striped" *ngFor="let student of studentdata | filter:{name:filterText,email:filterText,id:filterText} : false    | paginate: { itemsPerPage: 10,totalItems: totalLength, currentPage: p } ">
          <div class="Rtable-cell date-cell">
            <div class="Rtable-cell--heading">Application Id</div>
            <div class="Rtable-cell--content date-content" [ngStyle]="{'color': student?.status === 'accept' ? 'green' : ( student?.status === 'printreject' ? 'red' : 'black') }">{{student?.id}}
            </div>
          </div>
          <div class="Rtable-cell date-cell">
            <div class="Rtable-cell--heading">Name As Per Marksheet</div>
            <div class="Rtable-cell--content date-content"  [ngStyle]="{'color': student?.status === 'accept' ? 'green' : ( student?.status === 'printreject' ? 'red' : 'black') }">{{student?.fullname}}</div>
          </div>
          <div class="Rtable-cell topic-cell">
            <div class="Rtable-cell--heading">Email Address</div>
            <div class="Rtable-cell--content title-content"   [ngStyle]="{'color': student?.status === 'accept' ? 'green' : ( student?.status === 'printreject' ? 'red' : 'black') }">{{student?.email}}</div>
          </div>
          <div class="Rtable-cell date-cell" style="font-size: small;">
            <div class="Rtable-cell--heading">Application Tracker</div>
            <!-- <div class="Rtable-cell--content date-content"  [ngStyle]="{'color': student?.status === 'accept' ? 'green' : ( student?.status === 'printreject' ? 'red' : 'black') }">Tracker:{{student?.tracker}}</div> -->
            <div class="Rtable-cell--content date-content"  [ngStyle]="{'color': student?.status === 'accept' ? 'green' : ( student?.status === 'printreject' ? 'red' : 'black') }">print_by: {{student?.verified_by}}</div>
            <div class="Rtable-cell--content date-content"  [ngStyle]="{'color': student?.status === 'accept' ? 'green' : ( student?.status === 'printreject' ? 'red' : 'black') }">Print Date:{{student?.print_date}}</div>

          </div>
          <div class="Rtable-cell date-cell">
            <div class="Rtable-cell--heading">Application Status</div>
           


<!-- 
      <mat-form-field>
        <mat-select  [(ngModel)]="student.status" name="selectedYear" id="yearCtrl"
          (ngModelChange)="onChange($event,student.id)">
          <option  value="accept" >accept</option>
          <option   value="printreject">printreject</option>
        </mat-select>
      </mat-form-field> -->

   
          <nb-select placeholder="NgModel" [(ngModel)]="student.status" (ngModelChange)="onChange($event,student.id)" style="font-size: 10px; ">
            <nb-option value="accept">Accept</nb-option>
            <nb-option value="printreject">Reject</nb-option>
          </nb-select>


          </div>
          <div class="Rtable-cell date-cell">
            <div class="Rtable-cell--heading">Application Date</div>
            <div class="Rtable-cell--content date-content"   [ngStyle]="{'color': student?.status === 'accept' ? 'green' : ( student?.status === 'printreject' ? 'red' : 'black') }">{{student?.created_at}}</div>
          </div>
          <div class="Rtable-cell date-cell">
            <div class="Rtable-cell--heading">Print Certificate</div>
            <div class="Rtable-cell--content date-content">
              <!-- <i class="fa ion-person" status="info" nbPopover="View More" nbPopoverMode="hover"
              (click)="printJS(student.user_id,student.id,student.filepath)"></i><br> -->
              <i class="fa fa-file-pdf-o fa-5x" aria-hidden="true" status="info" nbPopover="View More" nbPopoverMode="hover"  [ngStyle]="{'color': student?.status === 'accept' ? 'green' : ( student?.status === 'printreject' ? 'red' : 'black') }"
              (click)="printJS(student.user_id,student.id,student.filepath,student.type)" ></i><br>
              <button nbButton outline size="small" *ngIf="student.otp_verify==0 && student.email_verification_token ==0" status="primary" (click)="verifyOtp('0', '0', student.id);">Verify</button>
              <button nbButton outline size="small" status="danger" *ngIf="student.userStatus == 'active'" (click)="status('inactive',student.id);">inactive</button>
              <button nbButton outline size="small" status="success" *ngIf="student.userStatus == 'inactive'" (click)="status('active',student.id);">active</button>
            </div>
          </div>
          <div class="Rtable-cell date-cell">
            <div class="Rtable-cell--heading">Print Address</div>
            <div class="Rtable-cell--content date-content" *ngIf="student.servicetype
            ==436 || student.servicetype
            ==298 ">
              <!-- <i class="fa ion-person" status="info" nbPopover="View More" nbPopoverMode="hover"
              (click)="printJSAddress(student.user_id,student.id)"></i><br>
             -->
             <i class="fa fa-file-pdf-o fa-5x" aria-hidden="true" status="info" nbPopover="View More" nbPopoverMode="hover"
             (click)="printJSAddress(student.user_id,student.id)"  [ngStyle]="{'color': student?.status === 'accept' ? 'green' : ( student?.status === 'printreject' ? 'red' : 'black') }" ></i><br>
             
            </div>
            <div class="Rtable-cell--content date-content" *ngIf="student.servicetype
            ==200">
              <!-- <i class="fa ion-person" status="info" nbPopover="View More" nbPopoverMode="hover"
              (click)="printJSAddress(student.user_id,student.id)"></i><br>
             -->
             <i class="fa fa-ban" aria-hidden="true"  [ngStyle]="{'color': student?.status === 'accept' ? 'green' : ( student?.status === 'printreject' ? 'red' : 'black') }"></i>
             <br>
             
            </div>
            <div class="Rtable-cell--content date-content" *ngIf="student.servicetype
            ==253">
              <!-- <i class="fa ion-person" status="info" nbPopover="View More" nbPopoverMode="hover"
              (click)="printJSAddress(student.user_id,student.id)"></i><br>
             -->
             <i class="fa fa-envelope" aria-hidden="true"  (click)="mailcertificate(student.user_id,student.id,student.email,student.filepath,email_admin)" ></i>
             <br>
             
            </div>
          </div>
          <div class="Rtable-cell date-cell">
            <div class="Rtable-cell--heading">Action</div>
            <div class="Rtable-cell--content date-content">
              <i class="fa ion-person" status="info" nbPopover="View More" nbPopoverMode="hover"  [ngStyle]="{'color': student?.status === 'accept' ? 'green' : ( student?.status === 'printreject' ? 'red' : 'black') }"
              (click)="handleClick(student.user_id,student.id)"></i><br>
              <button nbButton outline size="small" *ngIf="student.otp_verify==0 && student.email_verification_token ==0" status="primary" (click)="verifyOtp('0', '0', student.id);">Verify</button>
              <button nbButton outline size="small" status="danger" *ngIf="student.userStatus == 'active'" (click)="status('inactive',student.id);">inactive</button>
              <button nbButton outline size="small" status="success" *ngIf="student.userStatus == 'inactive'" (click)="status('active',student.id);">active</button><br>
              <i class="fa ion-clipboard" status="info" nbPopover="View More" nbPopoverMode="hover"  [ngStyle]="{'color': student?.status === 'accept' ? 'green' : ( student?.status === 'printreject' ? 'red' : 'black') }"
              (click)="addNotes(student.user_id,student.id)"></i><br>
              <i class="fa ion-refresh" status="info" nbPopover="View More" nbPopoverMode="hover"
              (click)="reason_add(student.id,student.user_id)"  [ngStyle]="{'color': student?.status === 'accept' ? 'green' : ( student?.status === 'printreject' ? 'red' : 'black') }"></i><br> <i class="fas fa-shipping-fast" status="info" nbPopover="View More" nbPopoverMode="hover"  [ngStyle]="{'color': student?.status === 'accept' ? 'green' : ( student?.status === 'printreject' ? 'red' : 'black') }"
              (click)="printdone(student.id,student.user_id,student.name,student.email,student.servicetype)"></i><br>

            </div>
          </div>
          <!-- <div class="Rtable-cell date-cell">
            <div class="Rtable-cell--heading">Note</div>
            <div class="Rtable-cell--content date-content"> -->
              <!-- <i class="fa ion-person" status="info" nbPopover="View More" nbPopoverMode="hover"
              (click)="addNotes(student.id)"></i><br> -->
              <!-- <i class="fa ion-clipboard" status="info" nbPopover="View More" nbPopoverMode="hover"  [ngStyle]="{'color': student?.status === 'accept' ? 'green' : ( student?.status === 'printreject' ? 'red' : 'black') }"
              (click)="addNotes(student.user_id,student.id)"></i><br>
            </div>
          </div> -->
          <!-- <div class="Rtable-cell date-cell">
            <div class="Rtable-cell--heading">Regenerate</div>
            <div class="Rtable-cell--content date-content">
              <i class="fa ion-refresh" status="info" nbPopover="View More" nbPopoverMode="hover"
              (click)="reason_add(student.id,student.user_id)"  [ngStyle]="{'color': student?.status === 'accept' ? 'green' : ( student?.status === 'printreject' ? 'red' : 'black') }"></i><br>
            </div>
          </div> -->
          <!-- <div class="Rtable-cell date-cell">
            <div class="Rtable-cell--heading">Print Done</div>
            <div class="Rtable-cell--content date-content">
              <i class="fas fa-shipping-fast" status="info" nbPopover="View More" nbPopoverMode="hover"  [ngStyle]="{'color': student?.status === 'accept' ? 'green' : ( student?.status === 'printreject' ? 'red' : 'black') }"
              (click)="printdone(student.id,student.user_id,student.name,student.email,student.servicetype)"></i><br>
            </div>
          </div> -->
          <div class="Rtable-cell date-cell">
            <div class="Rtable-cell--heading">Service type</div>
            <div class="Rtable-cell--content date-content" *ngIf="student.servicetype==436 || student.servicetype==298"  [ngStyle]="{'color': student?.status === 'accept' ? 'green' : ( student?.status === 'printreject' ? 'red' : 'black') }">Courier {{student.servicetype}}</div>
            <div class="Rtable-cell--content date-content" *ngIf="student?.servicetype==200"  [ngStyle]="{'color': student?.status === 'accept' ? 'green' : ( student?.status === 'printreject' ? 'red' : 'black') }">Pickup {{student.servicetype}}</div>
            <div class="Rtable-cell--content date-content" *ngIf="student?.servicetype==253"  [ngStyle]="{'color': student?.status === 'accept' ? 'green' : ( student?.status === 'printreject' ? 'red' : 'black') }">Mail {{student.servicetype}}</div>
          

                </div>
          <!-- <div class="Rtable-cell date-cell">
            <div class="Rtable-cell--heading">Profile Completeness</div>
            <div class="Rtable-cell--content date-content">
                <nb-progress-bar [value]="student.profile_completeness" outline status="info" [displayValue]="true">
                </nb-progress-bar>
            </div>
          </div> -->
        </div>
      </div>
      <div *ngIf="studentLength?.totalLength == 0">
        <span style="color : red; font-size: 18px;">No Data Available</span>
      </div>
    </div>
    <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
  </nb-card-body>
</nb-card>
<p-dialog [(visible)]="reason_value" header="Reason for Regenerate Certificate">
  <br/><br/>
  <div *ngIf="showreason">
  <div class="row">
    <div class="col-md-1">
      <h6 style="width: 100px;text-align: left;">Reason:</h6>
    </div>
    <div class="col-md-7" style="padding-left: 70px;width: max-content;">
      <input style="width: inherit;text-align: left;" nbInput id="reason" name="Reason" type="text" pattern="[a-zA-Z ]*" #reason >
    </div>
  </div>
<br><br>
    <div class="row">
      <div class="col-md-1">
        <button nbButton hero status="danger" size="small" (click)="regenerate(reason.value)">
          Save And Regenerate Certificate
        </button>
      </div>
    </div>
  </div>

</p-dialog>
<p-dialog [(visible)]="printdones" header="{{headers}}">
  <br/><br/>
  <div>

<br><br>
    <div class="row">
      <div class="col-md-1">
        <form [formGroup]="searchForm" class="color">
          <div>
            <div class="row">
              <div class="col-md-6">
                <p style="width: 100px;text-align: left;"><b>Date :</b></p>
              </div>
              <div class="col-md-6" style="padding-left: 130px;width: max-content;">
                <input nbInput placeholder="Pick Date"style="background-color: yellow;" [nbDatepicker]="dateTimePicker" formControlName="pickdatectrl" >
                <nb-datepicker #dateTimePicker></nb-datepicker><br>    
                  </div>
            </div>
            <div class="row"  *ngIf="courier">
              <div class="col-md-3">
                <p style="width: 100px;text-align: left;"><b>Tracking Id :</b></p>
              </div>
              <div class="col-md-7" style="padding-left: 130px;width: max-content;">
                <input style="width: inherit;text-align: left;background-color: yellow;" placeholder="Tracking Id"nbInput id="trackinid" formControlName="trackinid" type="text" pattern="[a-zA-Z ]*" #trackinid>
              </div>
            </div><br>
            <div class="row" >
              <div class="col-md-6">
                <p style="width: 100px;text-align: left;"><b>Company name:</b></p>
              </div>
              <div class="col-md-6" style="padding-left: 130px;width: max-content;">
                <input style="width: inherit;text-align: left;background-color: yellow;" placeholder="Courier Post" nbInput id="courier" formControlName="courier" type="text" pattern="[a-zA-Z ]*" #trackinid>
              </div>
            </div>
            <!-- <label for="css">CSS</label>
  <input type="text" id="css" name="fav_language" value="CSS"> -->
<!-- <div class="row" style="margin-bottom:7px">
<div class="col-md-6">Name</div>
<div class="col-md-6"> 
  <input type="text" nbInput fullWidth placeholder="Name" [readonly]="true">
</div>
</div> -->
      <br>
              <div class="row">
                <div class="col-md-1">
                  <button nbButton hero status="danger" size="small" (click)="save()">
                    Save 
                  </button>
                </div>
              </div>
            </div>
          </form>
      </div>
    </div>
  </div>
 
</p-dialog>
<p-dialog [(visible)]="display" [modal]="true" [responsive]="true" [style]="{width: '350px', minWidth: '200px'}" >

  <form [formGroup]="searchForm">
    <label><b>These are  Application id to merge Certificate</b></label>

    <div  *ngFor="let student of updatemerge">
      {{student.app_id}}
    </div>
    <button nbButton   status="primary" (click)=" ok(updatemerge)">ok</button>

  </form>

</p-dialog>
<p-confirmDialog [style]="{width: '60vw'}"></p-confirmDialog>
